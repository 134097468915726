import React, {useEffect, useState} from 'react';
import theme from '@style';
import {StyleSheet, View, ImageRequireSource} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import LazyImage from '@/components/basic/image';
import {useTranslation} from 'react-i18next';
import Text from '@basicComponents/text';
import {useLuckySpinModal} from '@/common-pages/luckyspin/luckyspin.hooks';
import globalStore from '@/services/global.state';
import {postSpinConfig} from '@/common-pages/luckyspin/luckyspin.service';
import {NoticeMap} from '../home/home.type';
import Tag from '@basicComponents/tag';

const {flex, padding, font, margin, background} = theme;

export interface MeRowMenuProps {
  onRecharge?: () => void;
  onWithdraw?: () => void;
  onInvitation?: () => void;
  noticeMap: NoticeMap;
  onNotice?: () => void;
}

const MeRowMenu: React.FC<MeRowMenuProps> = ({
  onRecharge,
  onWithdraw,
  onInvitation,
  onNotice,
  noticeMap,
}) => {
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    bottomNav: {
      height: calcActualSize(80),
    },
    bottomNavItem: {
      position: 'relative',
      width: calcActualSize(87),
    },
    vipNavsItemTag: {
      position: 'absolute',
      top: 5,
      right: 5,
    },
    upIcon: {
      width: calcActualSize(40),
      height: calcActualSize(40),
    },
  });
  const {i18n} = useTranslation();

  const [freeCount, setFreeCount] = useState<number>(0);
  const [spinBatchCount, setSpinBatchCount] = useState(30);
  const [spinBasePrice, setSpinBasePrice] = useState(10);
  const onRefreshSpinConfig = () => {
    if (globalStore.token) {
      postSpinConfig(true).then(data => {
        setFreeCount(data?.myFree || 0);
      });
    }
  };
  const doNotice = () => {
    onNotice?.();
    onRefreshSpinConfig();
  };

  const {renderModal: renderSpin, show: spinShow} = useLuckySpinModal({
    // drawImage,
    onNotice: doNotice,
    batchCount: spinBatchCount,
    singleAmount: spinBasePrice,
    freeCount: freeCount,
  });

  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      postSpinConfig(!!token).then(data => {
        // setDrawImage(data?.image);
        setSpinBasePrice(data?.singleAmount);
        setSpinBatchCount(data?.batchCount);
        setFreeCount(data?.myFree || 0);
        // Image.prefetch(data.image);
      });
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = ({
    url,
    title,
    onPress,
    num,
  }: {
    url: ImageRequireSource | string;
    title: string;
    onPress?: () => void;
    num?: number;
  }) => {
    return (
      <NativeTouchableOpacity
        style={[flex.center, styles.bottomNavItem]}
        onPress={onPress}>
        <View style={[flex.center]}>
          <View style={[styles.upIcon, flex.center]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={url}
              width={calcActualSize(50)}
              height={calcActualSize(50)}
            />
          </View>
          <Text style={[font.fs, font.main, margin.tops]}>{title}</Text>
        </View>
        {num && num !== 0 ? (
          <Tag
            style={styles.vipNavsItemTag}
            content={num < 0 ? '!' : num > 0 ? num : ''}
          />
        ) : null}
      </NativeTouchableOpacity>
    );
  };

  return (
    <View
      style={[
        flex.row,
        styles.bottomNav,
        flex.around,
        padding.lrl,
        margin.xs,
        margin.topxl,
        background.lightGrey,
      ]}>
      {renderItem({
        url: require('@assets/icons/me/recharge-white.webp'),
        title: i18n.t('me.bottom.recharge'),
        onPress: onRecharge,
      })}
      {renderItem({
        url: require('@assets/icons/me/spin-white.webp'),
        title: i18n.t('home.label.lucky-spin'),
        onPress: spinShow,
        num: noticeMap.LUCKY_SPIN,
      })}
      {renderItem({
        url: require('@assets/icons/me/withdraw-white.webp'),
        title: i18n.t('me.bottom.withdraw'),
        onPress: onWithdraw,
      })}
      {renderItem({
        url: require('@assets/icons/me/me-yaoqing.webp'),
        title: i18n.t('home.label.referFriend'),
        onPress: onInvitation,
      })}
      {renderSpin}
    </View>
  );
};

export default MeRowMenu;
