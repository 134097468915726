import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {downloadApk} from '@/utils'; //goTo
import globalStore from '@services/global.state';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, Image} from 'react-native';
import Text from '@basicComponents/text';
// import LinearGradient from '@/components/basic/linear-gradient';

const Download: React.FC = () => {
  const {i18n} = useTranslation();
  const [show] = React.useState(true); //setShow
  const toDownload = () => {
    // if (!globalStore.token) {
    //   globalStore.globalWaringTotal(i18n.t('home.tip.beforDownload'));
    //   goTo('Login');
    //   return;
    // }
    downloadApk();
  };
  const heightZoom = 50 / 375;
  const bannerHeight = globalStore.screenWidth * heightZoom;
  const downloadSizeH = (30 * globalStore.screenWidth) / 375;
  const downloadSizeW = (90 * globalStore.screenWidth) / 375;
  const imgSize = (35 * globalStore.screenWidth) / 375;
  // const iconSize = (theme.iconSize.l * globalStore.screenWidth) / 375;
  // const margin = (theme.paddingSize.s * globalStore.screenWidth) / 375;
  // const borderRadius = (18 * globalStore.screenWidth) / 375;
  return show ? (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        theme.background.primary,
        theme.margin.lrl,
        theme.flex.between,
        theme.borderRadius.xxxl,
        {
          height: bannerHeight,
          backgroundColor: '#00BB8F',
          paddingLeft: theme.paddingSize.l,
          paddingRight: theme.paddingSize.l,
          marginTop: 16,
        },
      ]}>
      <Image
        style={[
          {
            height: imgSize,
            width: imgSize,
            marginRight: theme.paddingSize.m,
          },
        ]}
        source={require('@assets/icons/logo.png')}
      />
      <View style={[theme.flex.flex, theme.flex.col, theme.flex.centerByRow]}>
        <Text main blod style={[theme.font.fm]} color={theme.fontColor.white}>
          {i18n.t('home.tip.downTips1')}
        </Text>
        <Text second style={[theme.font.fs]} color={theme.fontColor.white}>
          {i18n.t('home.tip.downTips2')}
        </Text>
      </View>
      <View style={[theme.flex.flex, theme.flex.row, theme.flex.centerByCol]}>
        <NativeTouchableOpacity onPress={toDownload}>
          {/* <LinearGradient
            colors={['#FFE125', '#FF8A00']}
            start={{x: 0, y: 0.5}}
            end={{x: 1, y: 0.5}}
            style={[
              theme.flex.center,
              theme.padding.lrxs,
              {
                height: downloadSizeH,
                width: downloadSizeW,
                marginLeft: theme.paddingSize.m,
                borderRadius: 18,
              },
            ]}>
            <Text
              color={theme.fontColor.white}
              style={{
                fontSize: 14,
                fontWeight: '500',
              }}>
              DOWNLOAD
            </Text>
          </LinearGradient> */}
          <Image
            style={[
              {
                height: downloadSizeH,
                width: downloadSizeW,
              },
            ]}
            source={require('@assets/icons/home-download.gif')}
          />
        </NativeTouchableOpacity>
        {/* <NativeTouchableOpacity
          style={[theme.flex.center, {height: iconSize, width: iconSize}]}
          onPress={() => setShow(false)}>
          <Image
            style={[
              {
                height: iconSize / 2,
                width: iconSize / 2,
                // marginRight: margin,
              },
            ]}
            source={require('@assets/icons/close-white.webp')}
          />
        </NativeTouchableOpacity> */}
      </View>
    </View>
  ) : (
    <></>
  );
};

export default Download;
